<!--
 * @Author: your name
 * @Date: 2021-04-22 21:10:32
 * @LastEditTime: 2021-05-28 15:02:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/Course.vue
-->
<template>
	<div class="course">
		<Header :baseInfo="baseInfo"/>
		<div class="swiper_banner" v-if="banner.length > 0" @mouseenter="on_swiper_enter" @mouseleave="on_swiper_leave">
			<swiper ref="mySwiperBanner" :options="swiperBanner" v-if="banner.length > 1">
				<swiper-slide v-for="(item , index) in banner" :key="index">
					<img style="width:100%;height:100%;" :src="item.pic" alt="">
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<img @click="adDetail(banner[0].url)" style="width:100%;" v-else :src="banner[0].pic" alt="">
		</div>
		<div class="before">
			<h4 class="home_title">六品书院天地格</h4>
			<p class="sub_title">天圆地方 定位字形 易学易练</p>
			<div class="course_items width_1200 margin_auto d_flex justify_content_between flex_wrap">
				<div class="course_item">
					<div class="c_title">
						<b></b>
						<h5>企业愿景</h5>
					</div>
					<p>基于科学布局，黄金比例设计，让书写过程更规范、更有效。</p>
				</div>
				<div class="course_item">
					<div class="c_title">
						<b></b>
						<h5>容易脱格</h5>
					</div>
					<p>强调范字的间架结构和笔画之间的位置关系，轻松突破格子约束。</p>
				</div>
				<div class="course_item">
					<div class="c_title">
						<b></b>
						<h5>定位精准</h5>
					</div>
					<p>圆线内部结合米字虚线，精准定位起笔、收笔、字体位置。</p>
				</div>
				<div class="course_item">
					<div class="c_title">
						<b></b>
						<h5>精致外观</h5>
					</div>
					<p>格子天圆地方，动静结合，间接提高练习者对笔画的精准控制。</p>
				</div>
			</div>
		</div>
		<div class="course_tab">
			<div class="tab_title width_1200 margin_auto" :style="'background:url('+baseImgUrl+'juanzhou_bg.png);background-size:contain;background-repeat:no-repeat;'">
				<div style="width:1000px;height:100%;margin:0 auto;" class="d_flex justify_content_around align_items_center">
					<div :class="tabIndex === 0 ? 'active' : ''" @click="courseTab(0)">硬笔课程体系</div>
					<div :class="tabIndex === 1 ? 'active' : ''" @click="courseTab(1)">软笔课程体系</div>
					<div :class="tabIndex === 2 ? 'active' : ''" @click="courseTab(2)">国画课程体系</div>
				</div>
			</div>
			<div class="tab_items">
				<el-image v-show="tabIndex === 0 ? true : false" :src="baseImgUrl + 'course_yinbi.png'"></el-image>
				<el-image v-show="tabIndex === 1 ? true : false" :src="baseImgUrl + 'course_ruanbi.png'"></el-image>
				<div v-show="tabIndex === 2 ? true : false">
					<el-image :src="baseImgUrl + 'course_guohua_1.jpg'"></el-image>
                    <el-image :src="baseImgUrl + 'course_guohua.jpg'"></el-image>
				</div>
				
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League :baseInfo="baseInfo" @openPop="openPop()"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>

<script>
import {config} from '../config';
import mixin from '../utils/mixin';
export default {
	mixins:[mixin],
	components:{
		"Form":()=>import('../components/Form')
	},
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			tabIndex:0,
		};
	},

	created(){
		
	},

	methods: {
		courseTab(index){
			this.tabIndex = index
		}
	},

	watch:{
		$route:{
			immediate:true,
			handler(newval){
				let type = newval.query.type*1
				// this.tabIndex = type === 1 ? 0 : type === 2 ? 1 : 0
				if (type === 1) {
					this.tabIndex = 0
				}
				if (type === 2) {
					this.tabIndex = 1
				}
				if (type === 3) {
					this.tabIndex = 2
				}

			}
		}
	},
}

</script>
<style lang='scss' scoped>
	.course{
		.course_banner{
			width:100%;
		}
		.home_title{
			margin-top:88px;
		}
		.course_items{
			margin-top:20px;
			.course_item{
				width:50%;
				margin-top:30px;
				p{
					margin-top:15px;
					text-align: left;
				}
				.c_title{
					color: #333333;
					font-size:24px;
					font-weight: bold;
					text-align: left;
					b{
						display: inline-block;
						width: 6px;
						height:12px;
						background: #B91829;
					}
					h5{
						display: inline-block;
						margin-left:10px;
					}
				}
			}
		}
		.course_tab{
			margin-top:70px;
			margin-bottom:70px;
			.tab_title{
				height:80px;
				div{
					width: 260px;
					height:50px;
					line-height: 50px;
					text-align: center;
					font-size: 24px;
					color:#fff;
					background: transparent;
					border-radius: 25px;
					cursor: pointer;
				}
				div.active{
					background: #B09475;
					font-weight: bold;
				}
			}
			.tab_items{
				margin-top:55px;
				.el-image{
					width:1200px;
				}	
			}
		}
	}

</style>

